import { Link, PageProps } from 'gatsby';
import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';

import ExperienceList from '../../components/ExperienceList';
import Layout from '../../components/Layout';
import PostList from '../../components/PostList';
import { ExperiencePreview } from '../../types/experience';
import { PostPreview } from '../../types/post';

import './Home.scss';

export interface HomePageContext {
  posts: PostPreview[];
  experiences: ExperiencePreview[];
}

const Home: React.FC<PageProps<unknown, HomePageContext>> = ({ pageContext: { posts, experiences } }) => (
  <Layout
    mainClassName="home"
  >
    <div className="home-tagline">
      <div className="container">
        <div>
          <h1>Saia da Inércia!</h1>
          <p>Experiências incríveis pra quem quer sair da rotina, se desafiar e conhecer o inesperado.</p>
          <p>As experiências mais incríveis do mundo reunidas em um único lugar!</p>
        </div>
      </div>
    </div>

    <div className="home-experiences">
      <div className="container">
        <h2>
          <span>
            Últimas Experiências
          </span>
        </h2>

        <ExperienceList experiences={experiences} />

        <Link to="/experiencias/">
          Mais Experiências Incríveis
          <FaLongArrowAltRight />
        </Link>
      </div>
    </div>

    <div className="home-posts">
      <div className="container">
        <h2>
          <span>
            Ideias & Inspirações
          </span>
        </h2>

        <PostList posts={posts} />

        <Link to="/ideias-e-inspiracoes/">
          Mais Ideias & Inspirações
          <FaLongArrowAltRight />
        </Link>
      </div>
    </div>
  </Layout>
);

export default Home;
